:root {
  // main colors
  --orange--primary: #d2794a;
  --white: #fff;
  --yellow: #fac762;
  --sky-blue: #0078d1;

  --form-gray: #dae1e6;

  // --form-gray with 0.25 opacity on white background
  --form-gray-opacity: #f6f7f9;
  --dashboard-gray: rgb(10, 57, 92, 0.1);
  // --button-blue with 0.1 opacity
  --bb-opacity: rgb(10, 57, 92, 0.1);
  // --button-red with 0.2 opacity
  --br-opacity: rgba(168, 0, 0, 0.2);

  // button colors
  --button-blue: #0a395c;
  --button-light-blue: rgb(10, 57, 92, 0.8);
  --button-gray: #9c9c9c;
  --light-gray: #f2f2f2;
  --darker-gray: #454545;
  --button-secondary: #849cad;
  --button-red: #a80000;
  --button-red-secondary: #820000;

  --green-success: #3e8b4e;
}
