.fileUpload_wrapper {
  margin-top: 40px;
}

.paymentWrapper {
  border-radius: 10px;
  background: var(--form-gray-opacity);
  padding: 30px;
}

.fileUpload_header {
  display: flex;
  align-items: baseline;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);

  b {
    font-weight: 900;
  }

  p {
    margin: 0;
    margin-bottom: 6px;
    transform: translateY(1px);

    @media (max-width: 900px) {
      transform: translateY(-1px);
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 26px;

    background-color: var(--button-blue);
    min-width: 26px;
    min-height: 26px;
    margin-right: 11px;
    border-radius: 50%;
  }
}

.fileUpload_dropSpace {
  height: 110px;
  display: flex;
  gap: 35px;
  padding-left: 35px;
  padding-right: 10px;
  align-items: center;

  background: #ffffff;
  border: 1px dashed var(--button-gray);
  border-radius: 5px;
  margin-top: 11px;

  @media (max-width: 900px) {
    gap: 15px;
    padding-left: 20px;
  }

  @media (max-width: 600px) {
    gap: 10px;
    padding-left: 12px;
  }

  @media (max-width: 480px) {
    height: 160px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    padding-bottom: 24px;

    svg {
      height: 36px;
      width: auto;
    }
  }
}

.fileUpload_link {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  span {
    cursor: pointer;
    color: var(--sky-blue);
    display: block;
    margin-left: 0;

    @media (max-width: 900px) {
      margin-left: 5px;
      display: inline;
    }
  }

  @media (max-width: 480px) {
    margin: 0;
  }
}

.fileUpload_example {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}

.fileUploaded {
  * {
    color: var(--green-success);
  }

  & > .fileUpload_dropSpace {
    border: 4px solid var(--green-success);
  }

  & > .fileUpload_header > div {
    background-color: var(--green-success);
  }

  span {
    color: var(--green-success);
  }
}

.fileUploadedWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.previewDocument {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteUploadButton {
  white-space: nowrap;
  overflow: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: var(--button-red);
  cursor: pointer;
}

.thumbnail {
  height: 30px;
  overflow: hidden;
  margin-right: 10px;

  img {
    border-radius: 1px;
    display: block;
    height: 32px;
    width: auto;
  }
}

.withError {
  border: 1px solid var(--button-red) !important;
  outline: none;
  box-shadow: 0px 0px 0px 5px var(--br-opacity);
}
