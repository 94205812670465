@import "styles/_mixins.scss";

.radioGroupWrapper {
  @include fieldWrapper;
}

.label {
  @include label;
}

.radioFormControl {
  gap: 10px;
}

.radioRoot {
  &:hover {
    background-color: transparent;
  }
}

.controlRoot {
  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
    margin: auto 0;
  }
}
