@import "styles/_mixins.scss";
/*
Profile box styles
*/

.appointmentsDashboardPadding {
  @include dashboardPadding;
}

.appointments_profileBoxWrapper {
  display: flex;
  border: 3px solid var(--button-blue);
  outline: 10px solid var(--form-gray);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;

  @include dashboardMobile {
    flex-direction: column;
    outline: unset;
  }

  & .svgFill {
    @include fillSvgSingleColor(var(--button-blue));
  }
}

.appointments_profileBoxData {
  margin-left: 30px;
  @include dashboardMobile {
    margin-left: unset;
  }
}

.appointments_profileBoxName {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin: 4px auto;
  color: var(--button-blue);
}

.appointments_profileBoxUserType {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin: 7px auto;
  color: var(--button-blue);
}

.appointments_profileBoxButton {
  margin: 15px 2px;
}

.appointments_profileBoxButton_button {
  width: 285px;
  height: 44px;
  box-sizing: border-box;
  background-color: var(--sky-blue);
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  @include dashboardMobile {
    width: unset;
    height: unset;
  }
}

.appointments_profileBoxLink {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  margin-left: 2px;
  color: var(--sky-blue);
  cursor: pointer;
}

/*
Add medical information
*/
.appointments_addMedicalInformation {
  height: 85px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid var(--button-red);
  border-radius: 5px;
  margin: 20px auto;
  padding: 30px;

  a:first-of-type {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
    text-decoration: none;
  }

  a:last-of-type {
    font-style: normal;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: var(--sky-blue);
  }
}

/*
 Appointments tabs
*/
.tabs_container {
  display: flex;
  gap: 1px;
}

.tab_upcomingTab {
  border: 1px solid var(--button-gray);
  border-radius: 5px 5px 0 0;
  flex: 1;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  background-color: var(--form-gray);
  transform: translateY(1px);
  cursor: pointer;
  @include dashboardMobile {
    padding-left: 15px;
  }
}

.tab_previousTab {
  border: 1px solid var(--button-gray);
  border-radius: 5px 5px 0 0;
  flex: 1;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  background-color: var(--form-gray);
  transform: translateY(1px);
  cursor: pointer;
  @include dashboardMobile {
    padding-left: 15px;
  }
}

.tabs_contentWrapper {
  border: 1px solid var(--button-gray);
  border-top: none;
  border-radius: 0px 0px 5px 5px;
}

.tab_contentUpcoming {
  padding: 10px;
}

.tab_contentPrevious {
  padding: 10px;
}

.activeTab {
  background-color: white;
  border-bottom: none;
}

.appointment_ctaButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: -25px;
  margin-right: -5px;
  @include TabletUI {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.appointments_emptyList {
  background-color: var(--form-gray-opacity);
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  color: var(--button-blue);
}

.pcpConfirmed {
  border: 3px solid var(--green-success);
  & .userText {
    color: var(--green-success);
  }

  & .svgFill {
    @include fillSvgSingleColor(var(--green-success));
  }
}

// PCP not appropriate red design is commented out if any time in future
// it would be required to enable it
// .pcpNotAppropriate {
//   border: 3px solid var(--button-red);
//   & .userText {
//     color: var(--button-red);
//   }

//   & .svgFill {
//     @include fillSvgSingleColor(var(--button-red));
//   }

//   & .paragraphDivWrapper {
//     margin-top: 9px;
//     margin-bottom: 14px;
//   }

//   & .paragraphTextTitle {
//     color: var(--button-blue);
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 22px;
//     margin: 0 0 3px 0;
//   }

//   & .paragraphText {
//     color: var(--button-blue);
//     margin: 0 0 3px 0;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 16px;
//   }
//   & .contactInfo {
//     & > p {
//       color: var(--button-blue);
//       margin: 0 0 3px 0;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 16px;
//     }

//     & .link {
//       font-weight: 700;
//       font-size: 16px;
//       line-height: 22px;
//       color: var(--sky-blue);
//     }
//   }
// }

.ListItemWrapper {
  padding: 10px;

  & .ListItem {
    display: flex;
    flex-direction: column;
    background-color: rgba(#dae1e6, 0.25);
    border-radius: 5px;

    & .ContentWrapper {
      margin: 20px 20px 15px;
      & .errorWrapper {
        display: flex;
        justify-content: center;
        & .errorText {
          color: var(--button-red);
        }
      }
    }

    & .title {
      color: var(--button-blue);
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }

    & .description {
      color: var(--button-blue);
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
    }

    & .openButtonWrapper {
      margin: 15px 0 0 0;
    }

    & .buttonStyle {
      border: 1px solid var(--sky-blue);
      width: 125px;
      height: 44px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding: unset;
    }
  }
}

.ListItemWrapper + .ListItemWrapper {
  padding-top: 0;
}

.loadingSpinner {
  color: var(--button-blue);
  align-self: center;
  padding: 15px 0;
}

.videoAppointmentLink {
  display: flex;
  border: 3px solid #3e8b4e;
  border-radius: 5px;
  background-color: var(--white);
  padding: 30px;
  margin: 30px 0 20px;

  & .button {
    padding: 12px 20px;
    height: 44px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  & {
    @include unstyledButton();
  }

  & span {
    color: var(--sky-blue);
    margin-left: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  @include dashboardMobile() {
    flex-direction: column;
    align-items: center;
    & button + .buttonWrapper {
      margin-top: 10px;
    }
    & span {
      margin-left: 0;
    }
  }
}

.appointmentDetails {
  padding: 0 60px 60px;

  @media (max-width: 900px) {
    padding: 30px 30px 30px 30px;
  }

  @media (max-width: 600px) {
    padding: 20px 18px 18px 18px;
  }

  @media (max-width: 480px) {
    padding: 20px 0 1px 0;
  }

  & .title {
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
    color: var(--button-blue);
    margin: 19px 0 40px;
  }

  &.height100 {
    height: 100%;
  }

  & .header {
    display: flex;
    align-items: center;
    height: 4.5rem;
    border-bottom: 1px solid var(--light-gray);

    @media (max-width: 900px) {
      margin-top: -2rem;
    }
  }

  & .goBackWrapper {
    position: relative;
    top: 10px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    & span {
      margin-left: 13.62px;
    }

    & .goBackText {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--sky-blue);
    }

    & {
      @include unstyledButton;
    }
  }

  & .linkSpace {
    box-sizing: border-box;
    padding: 29px 29px 21px;
    border: 1px solid #3e8b4e;
    border-radius: 5px;
    & span {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: var(--button-blue);
      @include unstyledButton;
    }
  }

  & .emptySpaceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  & .loadingSpinner {
    color: var(--button-blue);
  }

  & .appointmentData {
    display: flex;
    flex-direction: column;
    background-color: rgba(#dae1e6, 0.25);
    border-radius: 5px;
    margin-top: 20px;
    padding: 30px 30px 37px;

    & .section {
      display: flex;
      flex-direction: column;
      color: var(--button-blue);
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;

      & .sectionTitle {
        font-weight: 500;

        &.helpfulLinks {
          margin-bottom: 27px;
        }
      }

      & .helpfulLink {
        cursor: pointer;
        color: var(--sky-blue);
        text-decoration: underline;
        text-underline-offset: 2px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      @include unstyledButton;

      & .changeNumber {
        color: var(--button-red);
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-left: 20px;
      }

      & span:last-of-type,
      button:last-of-type {
        @include TabletUI {
          display: flex;
          flex-direction: column;
          margin-left: unset;
        }

        @include MobileUI {
          display: flex;
          flex-direction: column;
          margin-left: unset;
        }
      }

      & .tooLongDescription {
        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          margin-left: unset;
        }
      }
    }

    & .section + .section {
      margin: 29px 0 0;
      @include MobileUI {
        margin-top: 20px;
      }
    }
  }
}

.cancelledText {
  color: var(--button-red);
  font-size: 18px;
  font-weight: 500;
  margin: 2px auto 6px;
}

.modalStyle {
  color: var(--button-blue);
  font-size: 18px;
  line-height: 22px;

  span {
    font-weight: 500;
  }
}
