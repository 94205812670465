.formContainer_wrapper {
  padding: 40px 60px 1px 60px;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  background-color: #fff;

  @media (max-width: 900px) {
    padding: 30px 30px 1px 30px;
  }

  @media (max-width: 600px) {
    padding: 20px 18px 1px 18px;
  }

  @media (max-width: 480px) {
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
  }
}

/*
Action container styles
*/
.actionsContainer {
  margin: 10px;
  margin-top: 60px;
  background-color: var(--form-gray);
  border-radius: 0px 0px 10px 10px;
  width: calc(100% + 100px);
  margin-left: -50px;
  display: flex;

  @media (max-width: 900px) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
    box-sizing: border-box;
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}

.buttons {
  overflow: auto;
  margin: 40px auto 35px;
  display: flex;
  // flex-direction: column;
  align-items: center;
}

.stackButtons {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
