.thanksMessage {
  background: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 12px 40px 26px 30px;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
  }

  div {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
  }
}

.checkbox_wrapper {
  background: var(--form-gray-opacity);
  margin-top: 11px;
  display: flex;
  padding: 30px;
  border-radius: 10px;
}

.checkbox_label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: auto 0;
  margin-left: 20px;
}
