.errorWrapper {
  position: absolute;
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.positionRelative {
  margin-top: 22px;
  margin-bottom: -2px;
  position: relative;

  p {
    margin-bottom: 0;
  }
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}
