@import "styles/_mixins.scss";

.uploads_wrapper {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 10px;
  background: var(--form-gray-opacity);
  border-radius: 10px;

  @media (max-width: 900px) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
  }

  @media (max-width: 600px) {
    margin-left: -4px;
    margin-right: -4px;
    padding: 5px 15px 8px 20px;
  }
}

.uploads_talkToUs {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-blue);
  & {
    @include unstyledButton();
  }
}

.packages_optionWrapper {
  display: flex;
  justify-content: center;

  & {
    @include unstyledButton();
  }
}

.packages_optionDetails {
  background-color: white;
  border: 1px solid var(--button-gray);
  margin: 15px auto;
  margin-bottom: 9px;
  padding: 22px 30px 26px 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    background-color: transparent;
    cursor: default;
  }

  &.optionChosen {
    outline: 3px solid var(--green-success);
  }

  @include MobileUI {
    flex-direction: column;
    align-items: flex-start;
    div,
    p {
      margin-left: 0;
      margin-right: 0;
    }
    svg {
      width: 35px;
      height: 35px;
    }
  }
}

.packages_package {
  padding: 0;
  margin: 0 22px;
  display: flex;
  flex-direction: column;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
    margin: 8px 0;
  }

  div {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
  }

  &.enabled {
    p,
    div {
      color: var(--green-success);
    }
  }

  &.disabled {
    p,
    div {
      color: var(--button-gray);
    }
  }

  &.disabled div:last-of-type {
    font-family: "Work Sans";
    font-style: normal;
    color: var(--button-red);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
  }
}

.packages_infoIcon {
  height: 100%;
  margin: auto;
}

.packages_info {
  width: 20px;
  height: 20px;
  margin-left: 11px;
}

.speakToUs {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.speakToUsLink {
  color: var(--sky-blue);
  font-weight: 500 !important;
}

.hr {
  visibility: hidden;
}

.enabledIcon {
  & > svg {
    fill: var(--button-blue);
    & path {
      fill: var(--button-blue);
    }
    & rect {
      fill: var(--button-blue);
    }
    & circle {
      fill: var(--button-blue);
    }
  }
}

.disabledIcon {
  & > svg {
    fill: var(--button-gray);
    & path {
      fill: var(--button-gray);
    }
    & rect {
      fill: var(--button-gray);
    }
    & circle {
      fill: var(--button-gray);
    }
  }
}

.modalButtonSpacing {
  button + button {
    margin-left: 5px;
  }
}

.errorState {
  border: 1px solid var(--button-red);
  border: 1px solid var(--button-red);
  box-shadow: 0px 0px 0px 5px var(--br-opacity);

  * {
    color: var(--button-red) !important;
  }

  path {
    fill: var(--button-red) !important;
  }

  rect {
    fill: var(--button-red) !important;
  }

  circle {
    fill: var(--button-red) !important;
  }
}

.errorMessage {
  margin-top: 7px;
  margin-bottom: 12px;
}
