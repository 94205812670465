@import "styles/_mixins.scss";

.checkboxFieldWrapper {
  @include fieldWrapper;
  box-sizing: border-box;
  width: calc(100% + 60px);
  transform: translateX(-30px);
  background-color: var(--form-gray-opacity);
  padding: 30px;
  border-radius: 10px;

  @media (max-width: 900px) {
    width: 100%;
    transform: none;
  }
}

.checboxContainer {
  span {
    padding: 0;
  }
  display: flex;
  align-items: center;
}

.checkbox_label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: auto 0;
  margin-left: 18px;

  a {
    color: var(--sky-blue);
    margin: 0 4px;
  }
  span {
    display: inline;
    margin-left: -4px;
  }
}

.checkBoxErrorMessage {
  box-shadow: 0px 0px 0px 5px rgba(168, 0, 0, 0.2);
  border-radius: 5px;
  * {
    stroke: var(--button-red);
  }
}

.errorWrapper {
  margin-top: 12.5px;
  margin-bottom: 0;
  display: flex;
  align-items: top;

  p {
    margin-bottom: 0;
  }
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}
