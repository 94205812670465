.optionsWrapper {
  display: flex;
  gap: 30px;
  background-color: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  width: calc(100% + 60px);
  margin-left: -30px;

  @media (max-width: 900px) {
    gap: 20px;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin-left: 0;
  }
}

.option {
  flex: 1;
}

.singleOptionWrapper {
  width: 100%;
  display: flex;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  padding: 30px;
  gap: 30px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }
}

.singleOptionWrapper {
  svg {
    width: 72px;
    margin: auto;
    @media (max-width: 900px) {
      width: 35px;
      height: 35px;
      margin: 0;
    }
  }
}

.optionTitle {
  margin: 0;
  margin-bottom: 8px;
  color: var(--button-blue);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 900px) {
    margin-bottom: 6px;
  }
}

.optionDescription {
  color: var(--button-blue);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.activeOption {
  outline: 4px solid var(--green-success);
  width: 100%;

  * {
    color: var(--green-success) !important;
  }
}

.optionDisabled {
  background: transparent;
  * {
    color: var(--button-gray) !important;
  }
  svg {
    path {
      fill: var(--button-gray);
    }
  }
}
