.description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-top: 0;
  padding-right: 20px;

  @media (max-width: 600px) {
    padding-right: 10px;
  }
}

.subHeader {
  margin-bottom: 1px;
  color: var(--button-blue);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

ul {
  margin-top: 21px;
  margin-bottom: 22px;
  li {
    margin-bottom: 16px;
    padding-right: 10px;

    @media (max-width: 600px) {
      padding-right: 5px;
    }

    &::marker {
      font-size: 12px;
    }
  }
}
