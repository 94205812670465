@import "styles/_mixins.scss";

.completeStepper {
  @include completeStepper;
}

.title {
  @include successTitle;
}

.wrapper {
  background-color: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 30px 30px 28px;
  margin-bottom: 50px;
  box-sizing: border-box;
  width: calc(100% + 60px);
  margin-left: -30px;

  @media (max-width: 900px) {
    padding: 20px;
    // width: 100%;
    // margin-left: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media (max-width: 600px) {
    padding: 20px;
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}

.options {
  display: flex;
  gap: 30px;
  margin-bottom: 17px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.pcp {
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  flex: 1;
  display: flex;
  padding: 30px 4px 27px 30px;
  gap: 30px;
  cursor: pointer;
  background-color: #fff;

  @media (max-width: 900px) {
    display: inline;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
}

.childrenError {
  margin-top: 17px;
  color: var(--button-red);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.disabled {
  cursor: not-allowed;
  background: transparent;
  * {
    color: var(--button-gray) !important;
  }
  svg {
    path {
      fill: var(--button-gray);
    }
  }
}

.book {
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  flex: 1;
  display: flex;
  padding: 30px 4px 27px 30px;
  gap: 30px;
  cursor: pointer;
  background-color: #fff;

  @media (max-width: 900px) {
    display: inline;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
}

.whyLink {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: var(--sky-blue);
  margin-top: 17px;
  cursor: pointer;
}

.optionTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-bottom: 10px;
  text-align: left;

  @media (max-width: 900px) {
    margin-bottom: 2px;
  }
}

.optionDescription {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  text-align: left;
}

.optionIcon {
  margin: auto;

  @media (max-width: 900px) {
    svg {
      width: 35px;
      height: 35px;
      margin-bottom: 12px;
    }
  }
}

.activeOption {
  outline: 4px solid var(--green-success);

  * {
    color: var(--green-success);
  }
}

.optionError {
  border: 1px solid var(--button-red);
  border: 1px solid var(--button-red);
  box-shadow: 0px 0px 0px 5px var(--br-opacity);

  * {
    color: var(--button-red);
  }

  path {
    fill: var(--button-red);
  }
}

.errorWrapper {
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}
