@import "styles/_mixins.scss";

.signUp_cta {
  @include signUpCta;
}

.loadingCover {
  @include loadingCover();
}

.message {
  color: var(--button-blue);
  font-size: 18px;
  line-height: 21px;
}

.buttonLink {
  cursor: pointer;
  text-decoration: underline;
}
