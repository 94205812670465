.rdp-months {
  padding: 20px 20px 11px 20px;
  display: flex;
  justify-content: space-around;
}

.rdp-caption {
  @media (max-width: 750px) {
    position: relative;
  }
}

.rdp-caption_label {
  margin-top: 2px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--button-blue);

  @media (max-width: 750px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
    margin-left: 25px;
  }
}

.rdp-nav {
  background-color: var(--button-blue);
  color: #fff;
  border-radius: 25px;
  width: 25px;
  height: 25px;

  &:hover {
    background: none;
  }

  .rdp-nav_button {
    width: 25px;
    height: 25px;
  }

  .rdp-nav_icon {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 750px) {
    button[name="previous-month"] {
      position: absolute;
      left: 0;
      background-color: var(--button-blue);
    }
  }
}
.rdp-button {
  &:hover {
    background-color: none;
  }
}

.rdp-head_cell {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: var(--button-blue);
}

.rdp-cell {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--button-blue);
}
