/*
Stepper styles
*/
.stepper_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2px;
    margin-bottom: 18px;

    svg {
      width: 36px;
      height: 36px;
      margin-top: 30px;
      transform: translateX(-3px);
    }
  }
}

.stepper_circlesWrapper {
  display: flex;
  gap: 10px;
}

@mixin singleCircleBase {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 50%;
  font-weight: 700;
  font-size: 10px;
  line-height: 28px;
  background-color: var(--form-gray);
  color: white;
  cursor: default;
}

.stepper_singleCircle {
  & {
    @include singleCircleBase;
  }

  &_completed {
    @include singleCircleBase;
    background-color: var(--orange--primary);
  }

  &_active {
    @include singleCircleBase;
    background-color: var(--orange--primary);
    border: 1px solid white;
  }

  &_active_outline {
    border-radius: 50%;
    border: 1px solid var(--orange--primary);
    transform: translateY(-1px);
  }
}
