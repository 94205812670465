@import "styles/_mixins.scss";

.paymemtFormContainer {
  border-radius: 10px;
  padding: 30px;
  background: var(--form-gray-opacity);
  width: 100%;
  margin-left: -30px;

  &.marginBottomFix {
    margin-bottom: -20px;
  }

  @media (max-width: 900px) {
    padding: 20px;
    margin-left: -20px;
  }

  @media (max-width: 600px) {
    padding: 20px;
    box-sizing: border-box;
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  @media (max-width: 480px) {
    padding: 0px;
  }
}

.withoutActionContainer {
  margin-bottom: 30px;
}

.tabs_container {
  display: flex;
  gap: 1px;
}

.tab_useInsurance {
  border: 1px solid var(--button-gray);
  border-radius: 5px 5px 0 0;
  width: 100%;
  flex: 1;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  background-color: var(--form-gray);
  transform: translateY(1px);
  cursor: pointer;
}

.tab_selfPay {
  border: 1px solid var(--button-gray);
  border-radius: 5px 5px 0 0;
  width: 100%;
  flex: 1;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  background-color: var(--form-gray);
  transform: translateY(1px);
  cursor: pointer;
}

.tabs_contentWrapper {
  background-color: white;
  border: 1px solid var(--button-gray);
  border-top: none;
  border-radius: 0px 0px 5px 5px;
}

.activeTab {
  background-color: white;
  border-bottom: none;
}

.singleTabContent {
  padding: 30px;
  @media (max-width: 480px) {
    padding: 10px;
  }
}

.subTitle {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  margin-top: 14px;
  margin-bottom: 20px;
  color: var(--button-blue);

  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 1.1;
  }
}

.tabContent_wrapper {
  background: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 20px;
  padding-top: 1px;
}

.checkbox_wrapper {
  margin: 30px auto;
  margin-top: 11px;
  display: flex;
}

.checkbox_label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: auto 0;
  margin-left: 20px;
}

.fileUpload_wrapper {
  margin-top: 11px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.fileUpload_header {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 26px;

    background-color: var(--button-blue);
    min-width: 26px;
    min-height: 26px;
    margin-right: 11px;
    border-radius: 50%;
  }
}

.fileUpload_dropSpace {
  height: 110px;
  display: flex;
  gap: 35px;
  padding-left: 35px;
  align-items: center;

  background: #ffffff;
  border: 1px dashed var(--button-gray);
  border-radius: 5px;
  margin-top: 11px;

  @media (max-width: 900px) {
    gap: 15px;
    padding-left: 20px;
  }

  @media (max-width: 600px) {
    gap: 10px;
    padding-left: 12px;
  }
}

.fileUpload_example {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}

.checkboxStyle {
  width: 100%;
  transform: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.uploadDetailsTitle {
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  color: var(--button-blue);
  margin: 20px auto;
  margin-top: 56px;

  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 1.1;
  }
}

.uploadDetailsHelp {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
  width: 95%;
  color: var(--button-blue);

  span {
    color: var(--sky-blue);
    margin-left: 5px;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
}

.modalStyle {
  overflow-y: visible;
  margin-bottom: 27px;
}

.modalDescription {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-top: 2px;
  margin-bottom: 33px;
}

.modalItem {
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 22px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-left: -9px;
  color: var(--button-blue);

  svg {
    min-width: 35px;
    margin-top: 4px;
  }
}

.addressFieldInput {
  margin-bottom: 10px;
}

.insuranceProviderStyle .fieldUploadStyle {
  margin-top: 20px;
}

.fieldUploadStyle {
  margin-top: 20px;
}

.confirmationNote {
  color: var(--button-blue);
  margin: 3px auto 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  border: 2px solid var(--button-red);
  border-radius: 10px;
  padding: 21px 18px 22px;

  &.phoneButInsurance {
    margin: 3px auto 20px;
  }

  span {
    font-weight: 500;
  }
}

.appointmentConfirmationDetails {
  font-size: 18px;
  line-height: 21px;
  div {
    color: var(--button-red);
    font-weight: 500;
    margin-bottom: 2px;
  }

  p {
    color: var(--button-blue);
    margin: 1px 0;
  }

  & .videoRedText {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-red);
  }
}

.selfPayContentWrapper {
  .paymentInfo {
    border: 2px solid var(--button-red);
    border-radius: 10px;
    height: 65px;
    margin-bottom: 30px;
  }

  .textWrapper {
    margin: 21px 20px 14px;
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: unset;
      height: 100%;
    }
  }

  .paymentText {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
  }

  .checkoutContentWrapper {
    border-radius: 10px;
    height: 150px;
    background: rgba(#dae1e6, 0.25);
  }

  .checkoutContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .checkoutButton {
    border: none;
    margin: 20px 20px 26px;
    height: 65px;
    background-color: black;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Work Sans";
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    @include TabletUI {
      padding: 20px;
      height: 85px;
    }

    &.disabled {
      background-color: var(--button-gray);
    }
  }

  .checkoutLogoStripe {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 20px;

    & > * + * {
      margin-left: 7px;
    }
  }

  .checkoutLogoStripeText {
    color: var(--button-gray);
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  & .loadingSpinnerWrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    & .loadingSpinner {
      color: var(--button-blue);
    }
  }
}
