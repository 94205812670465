/*
Header styles
*/
.appBar_wrapper {
  height: 87px;
  background-color: var(--orange--primary);
  box-shadow: none;
  padding-right: 0 !important;

  @media (max-width: 480px) {
    height: 84px;
  }
}

.appBar_container {
  height: 100%;
}

.appBar_toolbar {
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appBar_logo {
  height: 100%;
  display: flex;
  cursor: pointer;

  & > img {
    margin-top: 7px;
  }
}

.hideLogoMobile {
  display: none;
  @media (min-width: 900px) {
    display: inherit;
  }
}

.appBar_buttonsWrapper {
  display: none;

  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }
}

.appBar_myProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  transform: translateY(-2px);
  cursor: pointer;

  svg {
    margin-left: 10px;

    path {
      fill: #fff;
    }
  }
}

/*
Container styles override
*/
.container {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 30px;
  }
}
/*
MainMenu styles
*/
.mainMenu_wrapper {
  display: none;

  @media (min-width: 900px) {
    height: 87px;
    background-color: var(--orange--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    height: 84px;
  }
}

.mainMenu_link {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
  color: white;

  &:hover {
    color: var(--light-gray);
    opacity: 0.5;
  }
}

.firstItem {
  padding-left: 1px;
}

/*
Offer styles
*/
.mainMenu_offerWrapper {
  height: 58px;
  background-color: var(--yellow);
  color: var(--button-blue);
  font-size: 18px;
  line-height: 20px;

  @media (max-width: 900px) {
    height: 64px;
    font-size: 17px;
  }

  @media (max-width: 600px) {
    height: 110px;
  }
}

.mainMenu_offer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    gap: 1px;
    text-align: center;
    padding: 20px;
    padding-bottom: 26px;
  }
}

.mainMenu_learnMore {
  color: var(--button-blue);
  font-weight: 600;
  margin-left: 6px;
}

/*
Drawer
*/
.drawerIcon {
  @media (min-width: 900px) {
    display: none;
  }
}

.drawerWrapper {
  background-color: var(--form-gray);
  width: 220px;
  height: 100%;
}

.drawerPaper {
  background-color: var(--form-gray);
}

.drawer_profile {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.drawer_profileBox {
  margin-top: 25px;
  margin-bottom: 8px;
  gap: 8px;

  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: var(--button-blue);
  }

  .loggedInUserWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.drawer_buttonWrapper {
  margin-left: 10px;
  margin-bottom: 15px;
  transform: translateY(-10px);
}

@mixin menuItemBase {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--button-blue);
  text-decoration: none;
  min-height: 42px;
  margin-left: 20px;
}

.drawer_menuItem {
  @include menuItemBase;
  margin-top: 10px;

  &_Logout {
    color: var(--button-red);
  }
}

.drawer_subItem {
  @include menuItemBase;
  padding-left: 15px;
  font-size: 14px;
  line-height: 32px;
  min-height: 14px;
}

.active_navLink {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--bb-opacity);
  transform: translateX(-20px);
  padding-left: 20px;
  box-sizing: border-box;
  width: calc(100% - 5px);

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.21967 11.0303C-0.0732233 10.7374 -0.0732233 10.2626 0.21967 9.96967L4.56434 5.625L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967C0.512563 -0.0732231 0.987437 -0.0732232 1.28033 0.21967L6.15533 5.09467C6.29598 5.23532 6.375 5.42609 6.375 5.625C6.375 5.82391 6.29598 6.01468 6.15533 6.15533L1.28033 11.0303C0.987437 11.3232 0.512563 11.3232 0.21967 11.0303Z' fill='%230A395C'/%3E%3C/svg%3E%0A");
    padding-top: 1px;
    margin-right: 8px;
  }
}

.mobileFormNav {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 900px) {
    display: none;
  }
}

.exitButton {
  margin-left: 26px;
}

.backStepAction {
  padding: 2px 7px 7px 4px;
  height: 32px;
  cursor: pointer;
}

.logoutButtonTooltip {
  padding: 17px 59px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: -6px -8px;
}

.actionTooltip {
  margin-top: -13px !important;
  margin-left: -14px !important;
}

/*
DropMenu
*/
.dropDownMenuWrapper {
  position: relative;

  & .menuItemTitle {
    position: relative;
    z-index: 5;
  }

  & .backgroundContainer {
    position: absolute;
    background-color: var(--button-blue);
    transform: translate(-22px, -40px);
    width: fit-content;
    z-index: 4;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgb(31, 31, 31);

    &.whatWeTreat {
      width: 149px;
    }

    &.aboutUs {
      width: 117px;
    }
  }

  & .openedDropdown {
    opacity: 1;
    z-index: 4;
    transition: all 0.3s;
  }

  & .closedDropdown {
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
  }
}

.dropDownMenu {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & .mainMenu_link {
    margin: auto;
    border-radius: 5px 5px 0 0;

    svg {
      margin-left: 5px;
      margin-bottom: 1px;
    }
  }
}

.expanded {
  background-color: var(--button-blue);
}

.dropDownListPadding {
  padding: 51px 20px 20px 22px;
  background-color: var(--button-blue);
}

.dropDownListItem {
  display: block;
  cursor: pointer;
  background-color: var(--button-blue);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;

  &:hover {
    color: var(--light-gray);
    opacity: 0.5;
  }
}
