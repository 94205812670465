@import "styles/_colors.scss";

// shared for all buttons
@mixin base {
  width: fit-content;
  margin-bottom: 5px;
  margin-right: 5px;
  text-transform: none;
  font-weight: 600;
  line-height: 20px;

  span {
    margin-left: 6px;
    font-weight: 400;
  }
}

// sets the background color and remove hover effect
@mixin background_color_and_hover($color) {
  background-color: $color;
  &:hover {
    background-color: $color;
  }
}

// sets text and border color
@mixin text_color_and_border($color) {
  color: $color;
  border: 1px solid $color;
  svg {
    fill: $color;
  }
}

// button sizes
.large {
  @include base;
  height: 60px;
  padding: 20px 30px;
  font-size: 16px;
  svg {
    font-size: 21px;
  }
}

.small {
  @include base;
  height: 44px;
  padding: 20px 30px;
  font-size: 14px;
  svg {
    font-size: 18px;
  }
}

// button styles
.blue {
  @include background_color_and_hover(var(--button-blue));
}

.light_blue {
  background-color: var(--button-light-blue);
  @include background_color_and_hover(var(--button-light-blue));
}

.gray {
  @include background_color_and_hover(var(--button-gray));
}

.light_gray {
  @include background_color_and_hover(var(--button-gray));
}

.secondary {
  @include background_color_and_hover(var(--button-secondary));
}

.red {
  @include background_color_and_hover(var(--button-red));
}

.red_secondary {
  @include background_color_and_hover(var(--button-red-secondary));
}

.red_outline {
  @include text_color_and_border(var(--button-red));
  @include background_color_and_hover(transparent);
}

.sky_blue {
  @include text_color_and_border(var(--white));
  @include background_color_and_hover(var(--sky-blue));
}

.outlined_blue {
  @include background_color_and_hover(var(--white));
  @include text_color_and_border(var(--button-blue));
}

.outlined_light_blue {
  @include background_color_and_hover(var(--white));
  @include text_color_and_border(var(--button-blue));
}

.outlined_gray {
  @include background_color_and_hover(var(--white));
  @include text_color_and_border(var(--button-gray));
}

.outlined_light_gray {
  @include background_color_and_hover(var(--white));
  @include text_color_and_border(var(--button-gray));
}

.outlined_secondary {
  @include background_color_and_hover(var(--white));
  @include text_color_and_border(var(--button-secondary));
}

.descriptionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  margin-left: 10px;
}

.loading {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}
