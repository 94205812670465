@import "styles/_mixins.scss";

.signUp_cta {
  @include signUpCta;
}

.resendText {
  color: var(--button-blue);
  font-size: 18px;
  font-weight: 300;

  span {
    font-weight: 500;
  }
}
