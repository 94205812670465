@import "styles/_mixins.scss";

// html {
// overflow: auto;
// overflow-y: scroll;
// }

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Work Sans", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.FormComponent-title {
  font-weight: 400;
  font-size: 60px;
  line-height: 1.15;
  color: var(--button-blue);
  margin-top: 6px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    font-size: 50px;
    margin-bottom: 38px;
  }

  @media (max-width: 600px) {
    font-size: 45px;
    margin-bottom: 28px;
  }

  @media (max-width: 480px) {
    font-size: 30px !important;
  }
}

.FormComponent-description {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 48px;
  color: var(--button-blue);

  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 44px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 1.1;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) {
    font-size: 20px !important;
  }
}

.fullPage-blueBackground {
  background-color: var(--button-blue);
  padding-top: 20px;
  min-height: calc(100vh - 107px);
  height: 100%;
  padding-bottom: 40px;

  @media (max-width: 480px) {
    padding: 0;
    background-color: white;
  }

  &.loadingStylesOverride {
    background-color: var(--button-blue);
    min-height: unset;
    height: 100vh;
    padding: unset;
  }
}

.page-mt-container {
  margin-top: 87px;

  @media (max-width: 480px) {
    margin-top: 84px;
  }
}

.page-container {
  padding-bottom: 32px;

  @media (max-width: 480px) {
    padding: 0;
  }
}

.Mui-focused {
  border: 1px solid var(--darker-gray);
  box-shadow: 0px 0px 0px 5px rgba(10, 57, 92, 0.2);

  input[type="password"] {
    color: #000;
  }
}

.link {
  color: var(--sky-blue);
  text-decoration: underline;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.disable-scroll {
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: hidden !important;
}

.disabledField {
  border: 1px solid var(--button-gray);
  background: var(--light-gray);
}

.disabledInput {
  -webkit-text-fill-color: var(--darker-gray) !important;
  font-weight: 400;
}

.disabledArrow {
  path {
    fill: var(--button-gray) !important;
  }
}
