.modalWrapper {
  width: 490px;
  max-width: 90%;
  z-index: 999999;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;

  &:focus-visible {
    outline: none;
  }
}

.modalTitle {
  margin: 15px 20px 20px;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: var(--button-blue);

  &.warningColor {
    color: var(--button-red);
  }
}

.modalDivider {
  width: 100%;
  height: 1px;
  background: var(--form-gray);
  opacity: 0.5;
}

.modalContainer {
  margin: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  max-height: 80vh;
  overflow-y: auto;

  @media (max-width: 600px) {
    max-height: 50vh;
  }

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--form-gray-opacity);
    -webkit-box-shadow: inset 0 0 6px var(--form-gray-opacity);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dashboard-gray);
  }
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-bottom: 5px;
  background: var(--form-gray-opacity);
  border-radius: 0px 0px 10px 10px;
}

.stackButtons {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
