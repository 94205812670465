@import "styles/_mixins.scss";

.dropDownFieldWrapper {
  @include fieldWrapper;
}

.label {
  @include label;
}

.dropdownInput {
  height: 55px;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  overflow: hidden;

  & > div {
    padding-left: 20px;
  }

  svg {
    width: 55px;
    height: 55px;
    top: -1px;
    right: 0px;
    border-left: 1px solid var(--button-gray);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: none;
    box-sizing: border-box;
    padding: 21px;

    path {
      fill: #000;
    }
  }
}

.menuItem {
  padding-left: 21px;
}

.expandedInput {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition-duration: 750ms, 750ms;

  svg {
    transform: rotate(180deg);
    border-left: none;
    border-right: 1px solid var(--button-gray);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.MuiPaper {
  margin-top: 8px;
  z-index: 9999;
  border: 1px solid var(--button-gray);
  border-radius: 0 0 5px 5px;
  box-shadow: none;
  transition-duration: 120ms, 45ms !important;
  max-height: 220px;

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--form-gray-opacity);
    -webkit-box-shadow: inset 0 0 6px var(--form-gray-opacity);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dashboard-gray);
  }
}

.MuiList {
  & li {
    &[aria-selected="true"] {
      background-color: var(--form-gray);

      &:hover {
        background-color: var(--light-gray);
      }
    }
    &:hover {
      background-color: var(--light-gray);
    }
  }
}

.formControll {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.muiSelectDropdownFix {
  @include muiSelectWidthFix;
}

.errorWrapper {
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}

.dropdownError {
  .dropdownInput {
    border: 1px solid var(--button-red);
    box-shadow: 0px 0px 0px 5px var(--br-opacity);
    svg {
      border-left: 1px solid var(--button-red);
      path {
        fill: var(--button-red);
      }
    }
  }
}
