@mixin label {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-bottom: 11px;
  white-space: normal;
}

@mixin fieldWrapper {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 55px;
}

@mixin loadingCover {
  opacity: 0.2;
}

@mixin completeStepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;

  @media (max-width: 480px) {
    padding: 0;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 25px;

    svg:last-child {
      width: 36px;
      height: 36px;
    }
  }
}

@mixin successTitle {
  color: var(--green-success) !important;

  @media (max-width: 480px) {
    font-size: 30px !important;
  }
}

@mixin signUpCta {
  position: absolute;
  margin: 20px auto;
  margin-left: 178px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--white);

  & > a {
    color: white;
    @media (max-width: 900px) {
      margin-left: 5px;
    }
  }

  @media (max-width: 900px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 480px) {
    color: var(--sky-blue);
    & > a {
      color: var(--sky-blue);
    }
  }

  @media (max-width: 380px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }
}

@mixin MobileUI {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin dashboardMobile {
  @media (max-width: 510px) {
    @content;
  }
}

@mixin TabletUI {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin unstyledButton {
  .buttonWrapper {
    all: unset;
    &.cursorPointer {
      cursor: pointer;
    }
    &.buttonLink {
      cursor: pointer;
      color: var(--sky-blue);
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}

@mixin muiSelectWidthFix {
  // fix for mui select component
  & > div {
    padding-right: 56px !important;
  }
}

@mixin fillSvgSingleColor($color) {
  & > svg {
    fill: $color;
    & path {
      fill: $color;
    }
    & rect {
      fill: $color;
    }
    & circle {
      fill: $color;
    }
  }
}

@mixin dashboardPadding {
  padding: 40px 60px 60px 60px;

  @media (max-width: 900px) {
    padding: 30px 30px 1px 30px;
  }

  @media (max-width: 600px) {
    padding: 20px 18px 1px 18px;
  }

  @media (max-width: 480px) {
    padding: 20px 0 1px 0;
  }
}
