@import "styles/_mixins.scss";

.completeStepper {
  @include completeStepper;
}

.title {
  @include successTitle;
}

.wrapper {
  background-color: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: calc(100% + 60px);
  margin-left: -30px;

  @media (max-width: 900px) {
    padding: 20px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media (max-width: 600px) {
    padding: 20px;
    width: calc(100% + 16px);
    margin-left: -8px;
  }

  & > .reservedSubtitle {
    margin-bottom: 20px;
  }
}

.reservedSubtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 6px;
  color: var(--button-blue);
}

.reservedDescription {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.17;
  margin: 0;
  color: var(--button-blue);

  span {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 500;
    color: var(--sky-blue);
    text-decoration: underline;
    cursor: pointer;
  }

  b {
    font-weight: 500;
  }
}

.addInfoWrapper {
  border: 1px solid var(--button-red);
  border-radius: 10px;
  padding: 30px 30px 28px;
  margin-bottom: 50px;
  box-sizing: border-box;
  width: calc(100% + 60px);
  margin-left: -30px;
  transform: translateY(10px);

  @media (max-width: 900px) {
    padding: 20px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media (max-width: 600px) {
    padding: 20px;
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}
