@import "styles/_mixins.scss";

.inputFieldWrapper {
  @include fieldWrapper;
}

.label {
  @include label;
}

.inputField {
  padding: 10px 20px;
  gap: 20px;
  width: 140px;
  height: 55px;
  border: 1px solid var(--button-gray);
  border-radius: 5px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.dob_inputsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 600px) {
    display: inline;
    & > div {
      margin-bottom: 30px;
    }
  }
}

.inputFieldError {
  border: 1px solid var(--button-red);
  box-shadow: 0px 0px 0px 5px var(--br-opacity);
}

.singleField_label {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-bottom: 11px;
}

.errorWrapper {
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}
