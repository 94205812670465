@import "styles/_mixins.scss";

.inputFieldWrapper {
  @include fieldWrapper;
  box-sizing: border-box;
}

.label {
  @include label;
}

.dropdownInput {
  color: black;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
  height: 55px;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  width: 100%;
  padding-left: 20px;

  svg {
    width: 55px;
    height: 55px;
    top: -1px;
    right: 0px;
    border-left: 1px solid var(--button-gray);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: none;

    path {
      fill: var(--form-gray);
    }
  }

  @include muiSelectWidthFix;
}

/*
Day Picker styles
*/
.dayPicker {
  width: 100%;
  background: #ffffff;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  box-sizing: border-box;
  margin: 0;

  .rdp-caption_label {
    color: red;
  }
}

.selected {
  border-radius: 2px;
  background-color: var(--green-success);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
