@import "styles/_mixins.scss";

.textArea_wrapper {
  @include fieldWrapper;
}

.textArea_label {
  @include label();
}

.textArea_input {
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  padding: 17px 10px 10px;
  gap: 20px;

  textarea {
    resize: auto;
    min-width: 100%;
  }

  textarea::-webkit-scrollbar {
    width: 1em;
  }

  textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--form-gray-opacity);
    -webkit-box-shadow: inset 0 0 6px var(--form-gray-opacity);
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: var(--dashboard-gray);
  }

  //   ::-webkit-resizer {
  //   }
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-top: 0;
  margin-bottom: 11px;
}

.errorWrapper {
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}
