@import "styles/_mixins.scss";

.dashboardShell_wrapper {
  display: flex;
  width: 100%;
  max-width: 980px;
  margin: 0px auto;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 550px;
  padding-bottom: 32px;

  @media (max-width: 480px) {
    border-radius: 0;
    background-color: white;
    box-shadow: none;
  }
}

.dashboardShell_menu {
  display: none;

  @media (min-width: 900px) {
    min-width: 210px;
    box-sizing: border-box;
    background-color: var(--form-gray);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px 0 0 5px;
    display: flex;
    flex-direction: column;
  }
}

.dashboardShell_container {
  width: 100%;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (min-width: 900px) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.navLink {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--button-blue);
  height: 43px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding-left: 20px;
}

.active_navLink {
  background-color: var(--dashboard-gray);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.21967 11.0303C-0.0732233 10.7374 -0.0732233 10.2626 0.21967 9.96967L4.56434 5.625L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967C0.512563 -0.0732231 0.987437 -0.0732232 1.28033 0.21967L6.15533 5.09467C6.29598 5.23532 6.375 5.42609 6.375 5.625C6.375 5.82391 6.29598 6.01468 6.15533 6.15533L1.28033 11.0303C0.987437 11.3232 0.512563 11.3232 0.21967 11.0303Z' fill='%230A395C'/%3E%3C/svg%3E%0A");
    padding-top: 1px;
    margin-right: 8px;
  }
}
