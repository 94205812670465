@import "styles/_mixins.scss";

.wrapper {
  @include fieldWrapper;
  margin-bottom: 0;
}

.label {
  @include label;
}

.timePickerWrapper {
  background: var(--form-gray);
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
  row-gap: 10px;
  column-gap: 10px;

  &.loading {
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: var(--button-blue);
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      transform: translateX(20px);
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.timeSlot {
  height: 45px;
  max-height: 45px;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--button-blue);
  cursor: pointer;

  &:hover {
    border: 2px solid var(--button-blue);
  }
}

.notAvailableSlot {
  border: 2px solid white !important;
  background-color: transparent;
  cursor: default;
}

.selected {
  background-color: var(--green-success);
  color: white;
  border: none !important;
  &:hover {
    opacity: 0.5;
  }
}

.noSlotsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--button-red);
  height: 175px;
}

.noSlotsMessage {
  color: var(--button-blue);
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  width: 435px;
}

.timeZoneMessage {
  font-weight: 400;
}
