@import "styles/_mixins.scss";

@mixin formWrapperBase {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    padding: 20px;
  }
}

.myProfilePaddingWrapper {
  @include dashboardPadding;
}

.expandableWrapper {
  height: 110px;
  @include formWrapperBase;
}

.expandedFormWrapper {
  @include formWrapperBase;
  border: 2px solid var(--darker-gray);
}

.expandableHead {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.expandedHeader {
  padding-bottom: 17px;
}

.formContainer {
  margin-left: -30px;
  margin-right: -30px;
  border-top: 1px solid var(--light-gray);

  & > div {
    padding: 0 30px;
  }
}

.contactUs {
  font-size: 18px;
  font-weight: 300;
  span {
    font-weight: 500;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: 0;
}

.cancel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-red);
  padding: 2px 4px;
  cursor: pointer;
}

.edit {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--sky-blue);
  padding: 2px 4px;
  cursor: pointer;
}

.description {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: 11px 0;
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 46px;
  transform: translateX(5px);
}

.tabContent_wrapper {
  background: var(--form-gray-opacity);
  border-radius: 10px;
  padding: 20px;
  padding-top: 1px;
}

.uploadDescription {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-bottom: 33px;
}

.uploadDescription {
  font-style: normal;
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  span {
    font-weight: 500 !important;
    cursor: pointer;
    color: var(--sky-blue);
    display: block;
    margin-left: 0;

    @media (max-width: 900px) {
      margin-left: 5px;
      display: inline;
    }
  }

  @media (max-width: 480px) {
    margin: 0;
  }
}

.hrLine {
  border-top: 1px solid var(--light-gray);
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 30px;
}

.dataNotEditable {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-red);
  margin-bottom: -8px;
  margin-top: 36px;
}

.modalStyle {
  overflow-y: visible;
  margin-bottom: 27px;
}

.modalDescription {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-top: 2px;
  margin-bottom: 33px;
}

.modalItem {
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 22px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-left: -9px;
  color: var(--button-blue);

  svg {
    min-width: 35px;
    margin-top: 4px;
  }
}

.loadingCover {
  @include loadingCover();
}

.currentEmail {
  color: var(--button-red);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 18px;
}

.mt30 {
  margin-top: 30px;
}

.contactDetails {
  font-weight: 500;
}

.note {
  color: var(--button-red);
  font-weight: 300;
  font-size: 18px;
  transform: translateY(-14px);
  margin-bottom: -4px;

  span {
    font-weight: 500;
  }
}

.removeBrMobile {
  @media (max-width: 600px) {
    br {
      display: none;
    }
  }
}
