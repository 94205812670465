.options {
  display: flex;
  gap: 20px;
  margin-bottom: 17px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.video {
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  width: 100%;
  flex: 1;
  display: flex;
  padding: 30px 4px 27px 15px;
  gap: 20px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;
}

.phone {
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  width: 100%;
  flex: 1;
  display: flex;
  padding: 30px 4px 27px 15px;
  gap: 20px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;
}

.optionTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-bottom: 10px;
  text-align: left;
}

.optionDescription {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  text-align: left;
}

.optionIcon {
  margin: auto;

  @media (max-width: 600px) {
    margin: auto 0;
  }
}

.activeOption {
  outline: 4px solid var(--green-success);
  width: 100%;

  * {
    color: var(--green-success);
  }
}

.typeLabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}

.phoneWrapper {
  width: 100%;
  margin-left: auto;

  @media (max-width: 600px) {
    width: 100%;
  }

  & > div {
    margin-bottom: 0 !important;
  }
}

.phoneNotAllowed {
  color: var(--button-red);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 12px;
  margin-bottom: -6px;
}
