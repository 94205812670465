@import "styles/_mixins.scss";

@mixin formWrapperBase {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    padding: 20px;
  }
}

.expandedFormWrapper {
  @include formWrapperBase;
  border: 1px solid var(--button-gray);
  margin-bottom: 60px;
}

.expandableHead {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.expandedHeader {
  padding-bottom: 17px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  color: var(--button-blue);
  margin: 0;
  padding-bottom: 8px;
}

.cancel {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-red);
  padding: 2px 4px;
  cursor: pointer;
}

.description {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: 11px 0 8px;
}

.formContainer {
  margin-left: -30px;
  margin-right: -30px;
  border-top: 1px solid var(--light-gray);

  & > div {
    padding: 0 30px;
  }
}

.bloodPressure {
  color: var(--button-blue);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.bloodPressureDesc {
  color: var(--button-blue);
  font-size: 18px;
  font-weight: 300;
  margin-top: 10px;
}
.systolicpressure {
  width: 50%;
}

.checkboxFormFix {
  width: 100%;
  transform: none;
  background: none;
  padding: 0;
  margin: 30px 0;
}

.completeStepper {
  @include completeStepper;
  flex-direction: row-reverse;
}

.titleGreen {
  @include successTitle;
}
