@import "styles/_mixins.scss";

/*
First Step
*/
.childInfo {
  margin-top: 23px;
  margin-bottom: -6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-blue);

  span {
    color: var(--sky-blue);
    text-decoration: underline;
    cursor: pointer;
  }
}

.modalDescription {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 22px;
  color: var(--button-blue);

  span {
    font-weight: 500;
  }
}

/*
Second Step
*/

.passwordValidation_box {
  margin-top: -38px;
  padding-left: 1px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: var(--button-blue);
    margin: 1px 0;
  }
}

@mixin passwordValidationBase {
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 24px;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 7px;
  }
}

.passwordError {
  @include passwordValidationBase;
  color: var(--button-red) !important;
}

.validPasswordCheckmark {
  @include passwordValidationBase;
  color: var(--green-success) !important;
}

/*
Third Step
*/

.checkbox_wrapper {
  margin-top: 11px;
  display: flex;
}

.checkbox_label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin: auto 0;
  margin-left: 20px;
}

.loadingCover {
  @include loadingCover();
}
/*
Fifth Step
*/
.upload_help {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: var(--sky-blue);
  cursor: pointer;
  margin-bottom: 42px;
  margin-top: -30px;
  padding-left: 1px;
}

.uploads_wrapper {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 10px;
  background: var(--form-gray-opacity);
  border-radius: 10px;

  @media (max-width: 900px) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
  }

  @media (max-width: 600px) {
    margin-left: -4px;
    margin-right: -4px;
    padding: 4px;
  }
}

.uploads_talkToUs {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}

.fileUpload_wrapper {
  margin-top: 11px;
  margin-bottom: 30px;
}

.fileUpload_header {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 26px;

    background-color: var(--button-blue);
    min-width: 26px;
    min-height: 26px;
    margin-right: 11px;
    border-radius: 50%;
  }
}

.fileUpload_dropSpace {
  height: 110px;
  display: flex;
  gap: 35px;
  padding-left: 35px;
  align-items: center;

  background: #ffffff;
  border: 1px dashed var(--button-gray);
  border-radius: 5px;
  margin-top: 11px;

  @media (max-width: 900px) {
    gap: 15px;
    padding-left: 20px;
  }

  @media (max-width: 600px) {
    gap: 10px;
    padding-left: 12px;
  }
}

.fileUpload_example {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}

/*
Sixth Step
*/
.packages_optionWrapper {
  display: flex;
  justify-content: center;
}

.packages_optionDetails {
  background-color: white;
  border: 1px solid var(--button-gray);
  margin: 15px auto;
  padding: 22px 30px 22px 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.packages_package {
  padding: 0;
  margin: 0 22px;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
    margin: 8px 0;
  }

  div {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: var(--button-blue);
  }
}

.packages_infoIcon {
  height: 100%;
  margin: auto;
}

.packages_info {
  width: 20px;
  height: 20px;
  margin-left: 11px;
}
