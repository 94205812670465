.loadingFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 107px);

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin-top: 24px;
    }
  }

  & .loadingText {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: white;
  }

  &.overrideHeight {
    height: 100vh;
  }
}
