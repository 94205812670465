@import "styles/_mixins.scss";

.inputFieldWrapper {
  @include fieldWrapper;
}

.label {
  @include label();
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
  margin-top: 0;
  margin-bottom: 11px;
}

.inputField {
  padding: 10px 20px;
  gap: 20px;
  height: 55px;
  border: 1px solid var(--button-gray);
  border-radius: 5px;
}

.filledInputStyle {
  border: 1px solid var(--darker-gray);
}

.sideLink {
  position: absolute;
  right: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--sky-blue);
  text-decoration: none;

  @media (max-width: 480px) {
    font-size: 14px;
    margin-top: 1px;
  }
}

.inputFieldError {
  border: 1px solid var(--button-red);
  box-shadow: 0px 0px 0px 5px var(--br-opacity);

  input[type="password"] {
    color: var(--button-red);
  }
}

.errorWrapper {
  position: absolute;
  margin-top: 12.5px;
  display: flex;
  align-items: top;
}

.errorIcon {
  margin-top: 2px;
}

.error {
  margin-top: 0px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--button-red);
}

.visibilityButton {
  color: var(--button-gray);
}

.lightLabel {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--button-blue);
}
